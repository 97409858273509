import classNames from "classnames";
import React from "react";
import {
  BsFileEarmarkArrowDownFill,
  BsFileEarmarkBarGraphFill,
  BsFileEarmarkExcelFill,
  BsFileEarmarkPdfFill,
  BsFileEarmarkPptFill,
  BsFileEarmarkTextFill,
  BsFileEarmarkWordFill,
  BsFileEarmarkZipFill
} from "react-icons/bs";
import Text from "../Text/Text";
import Link from "../Link/Link";
import styles from "./DownloadLink.module.scss";

interface DownloadLinkProps {
  to: string;
  text: string;
  fileType?: string;
  className?: string;
  analyticsDownloadId?: string;
}

const DownloadLink = ({
  to,
  text,
  fileType,
  className,
  analyticsDownloadId
}: DownloadLinkProps) => {
  const getFileTypeIcon = () => {
    switch (fileType) {
      case "docx":
      case "doc":
        return BsFileEarmarkWordFill;
      case "xlsx":
      case "xls":
        return BsFileEarmarkExcelFill;
      case "pptx":
      case "ppt":
        return BsFileEarmarkPptFill;
      case "pdf":
        return BsFileEarmarkPdfFill;
      case "ai":
        return BsFileEarmarkBarGraphFill;
      case "txt":
        return BsFileEarmarkTextFill;
      case "zip":
        return BsFileEarmarkZipFill;
      default:
        return BsFileEarmarkArrowDownFill;
    }
  };
  const Icon = getFileTypeIcon();
  return (
    <Link
      className={classNames(styles.root, className)}
      to={to}
      analyticsDownloadId={analyticsDownloadId}
    >
      <Icon color="#000" size="30" />
      <Text variant="defaultBold">{text}</Text>
    </Link>
  );
};

export default DownloadLink;
