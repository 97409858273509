import classNames from "classnames";
import React from "react";
import BlockContent from "../../components/BlockContent/BlockContent";
import { SanitySponsor } from "../../model/common";
import { imageUrlBuilder } from "../../utils/images";
import styles from "./SponsorCredit.module.scss";

type Size = "sm" | "lg";

const SponsorCredit = ({
  className,
  size = "sm",
  sponsor: { _rawText, logo }
}: {
  size?: Size;
  className?: string;
  sponsor: SanitySponsor;
}) => {
  const logoWidth = size === "sm" ? 111 : 222;
  const logoHeight =
    logoWidth / (logo?.asset.metadata.dimensions.aspectRatio ?? 1);
  return (
    <div className={classNames(styles.root, className, size)}>
      {logo && logo.asset && (
        <img
          height={logoHeight}
          width={logoWidth}
          alt={logo.alt}
          src={
            imageUrlBuilder
              .image(logo)
              .fit("fill")
              .width(logoWidth * 2)
              .bg("fff")
              .url()!
          }
        />
      )}
      {_rawText && (
        <BlockContent bodyVariant="defaultTiny" content={_rawText} />
      )}
    </div>
  );
};

export default SponsorCredit;
