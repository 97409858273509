import { WindowLocation } from "@reach/router";
import { graphql } from "gatsby";
import React from "react";
import Breadcrumbs, { Breadcrumb } from "../components/Breadcrumbs/Breadcrumbs";
import Card from "../components/Card/Card";
import CreditedContent from "../components/CreditedContent/CreditedContent";
import DownloadLink from "../components/DownloadLink/DownloadLink";
import { Col, Row, Wrapper } from "../components/Grid";
import { internalLinkUrl } from "../components/InternalLink/InternalLink";
import PdfEmbed from "../components/PdfEmbed/PdfEmbed";
import SocialLinks from "../components/SocialLinks/SocialLinks";
import WithSpacing from "../components/Spacing/Spacing";
import Text from "../components/Text/Text";
import PageLayout from "../layouts/PageLayout/PageLayout";
import { CommonContext, SanityPage } from "../model/common";
import { GlobalMenus } from "../model/menu";
import { SanityResourcePage } from "../model/teach";
import { urlForDocument } from "../urls";
import { SiteArea } from "../utils/analytics";
import { downloadUrl } from "../utils/cdn";
import styles from "./ClassroomResourcePage.module.scss";

export const pageQuery = graphql`
  query ResourcePage($_id: String!, $navLanguage: String) {
    menus: sanityGlobalConfig(language: { eq: $navLanguage }) {
      ...MenuData
    }
    resourceListingPage: sanityResourceListingPage {
      _id
      _type
      title
    }
    page: sanityResource(_id: { eq: $_id }) {
      _id
      _type
      title
      _rawContent(resolveReferences: { maxDepth: 5 })
      image {
        ...SanityImage
      }
      slug {
        current
      }
      socialTitle
      socialDescription
      socialImage {
        ...SanityImage
      }
      downloads {
        _id
        _type
        name
        file {
          asset {
            ...SanityFileAsset
          }
        }
        alternatives {
          language
          file {
            asset {
              ...SanityFileAsset
            }
          }
        }
      }
      translationSponsors {
        ...TranslationSponsors
      }
      licence {
        ...Licence
      }
    }
  }
`;

interface ResourcePageProps {
  data: {
    menus: GlobalMenus;
    page: SanityResourcePage;
    resourceListingPage: SanityPage;
  };
  location: WindowLocation;
  pageContext: CommonContext;
}

const ResourcePage = ({
  pageContext,
  data: { menus, page, resourceListingPage },
  location
}: ResourcePageProps) => {
  const { title, downloads } = page;
  const breadcrumbs: Breadcrumb[] = [
    {
      title: resourceListingPage.title,
      href: urlForDocument(resourceListingPage)
    },
    {
      title: page.title,
      href: urlForDocument(page)
    }
  ];
  const pdf = page.downloads.find(
    d => d.file.asset.extension?.toLowerCase() === "pdf"
  );
  return (
    <PageLayout
      menus={menus}
      siteArea={SiteArea.TEACH}
      metadata={{
        title,
        page,
        alternates: pageContext.alternates
      }}
      location={location}
      strings={pageContext.strings}
    >
      <Wrapper className={styles.root}>
        <Row justify="center">
          <Col lg={8} md={10} sm={12}>
            <Row justify="between" style={{ marginBottom: "10px" }}>
              <Col>
                <Breadcrumbs breadcrumbs={breadcrumbs} />
              </Col>
              <Col>
                <SocialLinks title={title} />
              </Col>
            </Row>
            <WithSpacing>
              <Card type="main">
                <Text variant="h1">{title}</Text>
                {pdf && (
                  <PdfEmbed
                    id={pdf._id}
                    src={downloadUrl(pdf.file.asset, {
                      showInline: true,
                      overrideFilename: pdf.name ? pdf.name : undefined
                    })}
                  />
                )}
                <div className={styles.downloads}>
                  <Text variant="h3">Files</Text>
                  <ul>
                    {downloads.map(download => (
                      <li>
                        <DownloadLink
                          to={internalLinkUrl(
                            download,
                            undefined,
                            undefined,
                            undefined
                          )}
                          fileType={download.file.asset.extension}
                          text={download.name}
                        />
                      </li>
                    ))}
                  </ul>
                </div>
                <CreditedContent
                  content={page._rawContent}
                  cap="3/4"
                  licence={page.licence}
                  translationSponsors={page.translationSponsors}
                />
              </Card>
            </WithSpacing>
          </Col>
        </Row>
      </Wrapper>
    </PageLayout>
  );
};

export default ResourcePage;
