import { SkipNavContent, SkipNavLink } from "@reach/skip-nav";
import "@reach/skip-nav/styles.css";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import AspectRatio from "../AspectRatio/AspectRatio";
import styles from "./PdfEmbed.module.scss";

type Scenario = "iOS" | "other";

interface PdfEmbedProps {
  id: string;
  src: string;
  title?: string;
}

const PdfEmbed = ({ id, src, title }: PdfEmbedProps) => {
  const [scenario, setScenario] = useState<Scenario>();
  useEffect(() => {
    setScenario(iOS() ? "iOS" : "other");
  }, []);
  return (
    <>
      <div className={styles.skipLinkContainer}>
        <SkipNavLink contentId={id} className={styles.skipNavLink}>
          Skip embedded preview
        </SkipNavLink>
      </div>
      <AspectRatio
        ratio={16 / 9}
        className={classNames(styles.root, scenario && styles[scenario])}
      >
        {scenario && (
          <object
            type="application/pdf"
            aria-label={title ?? "File preview"}
            data={src}
          />
        )}
      </AspectRatio>
      <SkipNavContent id={id} />
    </>
  );
};

const iOS = () => {
  return (
    [
      "iPad",
      "iPad Simulator",
      "iPhone",
      "iPhone Simulator",
      "iPod",
      "iPod Simulator"
    ].includes(navigator.platform) ||
    // iPad on iOS 13+ uses desktop user agent
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  );
};

export default PdfEmbed;
